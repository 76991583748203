<template>
  <div class="info-three">
    <van-cell-group title="投资目标及经验">
      <van-field
        v-model="form.invest_knowledge"
        label="投资知识"
        @click="showSheet('invest_knowledge')"
        placeholder="请选择"
        right-icon="arrow"
        input-align="right"
        readonly
      />
      <van-cell value="投资目标">
        <template #extra>
        <van-checkbox-group class="check-group" v-model="form.invest_target">
            <van-checkbox name="增值" shape="square">增值</van-checkbox>
            <van-checkbox name="收入" shape="square">收入</van-checkbox>
            <van-checkbox name="投机" shape="square">投机</van-checkbox>
            <van-checkbox name="保值" shape="square">保值</van-checkbox>
            <van-checkbox name="对冲" shape="square">对冲</van-checkbox>
            <van-checkbox name="其它" shape="square">其它</van-checkbox>
          </van-checkbox-group>
        </template>
      </van-cell>
      <van-field
        v-if="showOtherTarget"
        v-model="form.other_target"
        clearable
        label="其它投资目标"
        maxlength="100"
        placeholder="请输入"
        input-align="right"
      />
      <van-field
        v-model="form.monthly_transaction_frequency"
        label="每月交易频率"
        @click="showSheet('monthly_transaction_frequency')"
        placeholder="请选择"
        input-align="right"
        right-icon="arrow"
        readonly
      />
      <van-field
        v-model="form.stock_exp"
        label="股票投资经验"
        @click="showSheet('stock_exp', 'exp')"
        input-align="right"
        placeholder="请选择"
        right-icon="arrow"
        readonly
      />
      <van-field
        v-model="form.futures_exp"
        label-width="9em"
        label="期货/期权投资经验"
        @click="showSheet('futures_exp', 'exp')"
        placeholder="请选择"
        input-align="right"
        right-icon="arrow"
        readonly
      />
      <van-field
        v-model="form.fund_exp"
        label="基金投资经验"
        @click="showSheet('fund_exp', 'exp')"
        placeholder="请选择"
        input-align="right"
        right-icon="arrow"
        readonly
      />
      <van-field
        v-model="form.bond_exp"
        label="债券投资经验"
        @click="showSheet('bond_exp', 'exp')"
        placeholder="请选择"
        input-align="right"
        right-icon="arrow"
        readonly
      />
      <van-field
        v-model="form.cip_exp"
        label-width="11em"
        label="结构性投资产品投资经验"
        @click="showSheet('cip_exp', 'exp')"
        placeholder="请选择"
        input-align="right"
        right-icon="arrow"
        readonly
      />
    </van-cell-group>
    <van-action-sheet
      v-model:show="isShowSheet"
      :actions="actions"
      @select="onSheetSelect"
      cancel-text="取消"
      close-on-click-action
    />
    <div style="width: 100%;text-align: center;">
      <van-button type="primary" class="btn" @click="next" :disabled="disabledNext">
        下一步(其它资料)
      </van-button>
      <van-button class="btn" @click="before">
        上一步
      </van-button>
    </div>
  </div>
</template>

<script>
import {
  Button, CellGroup, Field, ActionSheet, Popup, Picker, Cell, Checkbox, CheckboxGroup,
} from 'vant';
import { useStore } from 'vuex';
import {
  onMounted, ref, watch, reactive,
} from 'vue';
import httpApi from '../../utils/httpApi';

const invest_knowledge = [{ name: '精通' }, { name: '良好' }, { name: '较好' }, { name: '有限' }, { name: '全无' }];
const monthly_transaction_frequency = [{ name: '0-5次' }, { name: '5-20次' }, { name: '20次以上' }];
const exp = [{ name: '无经验' }, { name: '小于1年' }, { name: '1-3年' }, { name: '3-5年' }, { name: '大于5年' }];
let submitForm;
export default {
  name: 'Home',
  components: {
    [Button.name]: Button,
    [Field.name]: Field,
    [CellGroup.name]: CellGroup,
    [ActionSheet.name]: ActionSheet,
    [Popup.name]: Popup,
    [Picker.name]: Picker,
    [Cell.name]: Cell,
    [Checkbox.name]: Checkbox,
    [CheckboxGroup.name]: CheckboxGroup,
  },
  data() {
    return {
      isShowSheet: false,
      actions: [],
      actionKey: '',
      invest_knowledge,
      monthly_transaction_frequency,
      exp,
    };
  },
  methods: {
    showSheet(key, actionKey = key) {
      this.isShowSheet = true;
      this.actions = this[`${actionKey}`];
      this.actionKey = key;
    },
    onSheetSelect(value) {
      this.form[`${this.actionKey}`] = value.name;
      this.actions = [];
      this.actionKey = '';
    },
    async next() {
      if (this.disabledNext.value) return;
      const { code, msg } = await this.$api.postInfoStepThree(submitForm);
      if (code === 200) {
        this.$router.push({ path: '/info/step_four' });
      } else {
        this.$toast(msg);
      }
    },
    before() {
      this.$router.go(-1);
    },
  },
  setup() {
    const form = reactive({
      invest_knowledge: '',
      invest_target: [],
      other_target: '',
      monthly_transaction_frequency: '',
      stock_exp: '',
      futures_exp: '',
      fund_exp: '',
      bond_exp: '',
      cip_exp: '',
    });
    onMounted(async () => {
      const store = useStore();
      store.commit('setTitleValue', '资料填写');
      store.commit('setStepsValue', ['填写资料(3/5)', '风险认知', '提交资料']);
      store.commit('setStepsActive', 0);
      const result = await httpApi.getInfoStepThree();
      if (!result) return;
      Object.getOwnPropertyNames(form).forEach((item) => {
        if (result.data[item]) {
          form[item] = result.data[item];
        }
      });
    });
    const showOtherTarget = ref(false);
    const disabledNext = ref(true);
    watch(form, (val) => {
      submitForm = { ...val };
      showOtherTarget.value = true;
      if (submitForm.invest_target.indexOf('其它') === -1) {
        showOtherTarget.value = false;
        delete submitForm.other_target;
      }
      disabledNext.value = false;
      Object.getOwnPropertyNames(submitForm).forEach((item) => {
        if (item === 'invest_target') {
          submitForm[item] = [...submitForm[item]];
          if (submitForm[item].length === 0) disabledNext.value = true;
        } else if (!submitForm[item].trim()) {
          disabledNext.value = true;
        }
      });
      httpApi.formLog(submitForm, disabledNext);
    });

    return {
      form,
      showOtherTarget,
      disabledNext,
    };
  },
};
</script>

<style lang="less">
  .info-three {
    text-align: left;
    .check-group {
      width: 200px;
      height: 90px;
      display: flex;
      justify-content: space-around;
      align-items: space-around;
      flex-wrap: wrap;
      & > div {
        flex-basis: 50%;
      }
    }
    .btn {
      width: 80%;
      margin: @space-0 auto;
      margin-bottom: 0;
    }
  }
</style>
